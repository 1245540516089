<template>
  <b-container fluid>
    <bo-page-title />
    <section id="webSettings">
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Website Information</h5>
        </template>


        <validation-observer
            v-slot="{ handleSubmit }" ref="VFormSite"
        >
        <b-form @submit.prevent="handleSubmit(submitSite)">
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group label="Footer Company Information (ID)" label-for="companyInfo">
                  <b-form-textarea id="companyInfo" v-model="websiteInformation.footer_desc_id" @keyup="removeWildChar" @keydown="removeWildChar" rows="4" />
                    <VValidate 
                        name="Footer Company Id" 
                        v-model="websiteInformation.footer_desc_id"
                        :rules="{...validationSite.footer_desc_id,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                    />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Footer Company Information (EN)" label-for="companyInfoEn">
                  <b-form-textarea id="companyInfoEn" v-model="websiteInformation.footer_desc_en" @keyup="removeWildChar" @keydown="removeWildChar" rows="4" />
                    <VValidate 
                        name="Footer Company En" 
                        v-model="websiteInformation.footer_desc_en"
                        :rules="{...validationSite.footer_desc_en,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                    />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="4">
                    <b-form-group label-for="companyEmail" label="Company Email">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text><i class="ti-email"></i></b-input-group-text>
                        </template>
                        <b-form-input v-model="websiteInformation.email" id="companyEmail" type="email" />
                      </b-input-group>
                        <VValidate 
                            name="Email" 
                            v-model="websiteInformation.email"
                            :rules="validationSite.email" 
                        />
                    </b-form-group>
                    <b-form-group label-for="faceBookLink" label="Facebook Link">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text><i class="ti-facebook"></i></b-input-group-text>
                        </template>
                        <b-form-input v-model="websiteInformation.facebook" id="faceBookLink"/>
                      </b-input-group>
                        <VValidate 
                            name="Facebook" 
                            v-model="websiteInformation.facebook"
                            :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                        />
                    </b-form-group>
                    <b-form-group label-for="linkedIn" label="LinkedIn Link">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text><i class="ti-linkedin"></i></b-input-group-text>
                        </template>
                        <b-form-input v-model="websiteInformation.linkedIn" id="linkedIn"/>
                      </b-input-group>
                        <VValidate 
                            name="LinkedIn" 
                            v-model="websiteInformation.linkedIn"
                            :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                        />
                    </b-form-group>
                    <b-form-group label-for="companyIg" label="Instagram Link">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text><i class="ti-instagram"></i></b-input-group-text>
                        </template>
                        <b-form-input v-model="websiteInformation.instagram" id="companyIg"/>
                      </b-input-group>
                        <VValidate 
                            name="Instagram" 
                            v-model="websiteInformation.instagram"
                            :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                        />
                    </b-form-group>
                    <b-form-group label="Phone Number" label-for="phoneNumber1">
                      <template>
                        <b-form-row class="align-items-top mb-2">
                          <b-col md="9">
                            <b-input-group>
                              <template #prepend>
                                <b-input-group-text><i class="icon-phone"></i></b-input-group-text>
                              </template>
                              <b-form-input v-model="websiteInformation.telephone" type="text"/>
                            </b-input-group>
                            <VValidate 
                              :name="'telephone'" 
                              v-model="websiteInformation.telephone"
                              :rules="{regex:/^[0-9\+ ]+$/, required: true}" 
                            />
                          </b-col>
                        </b-form-row>
                      </template>
                    </b-form-group>
                    <div>
                    </div>
                  </b-col>
                  <b-col md="7">
                    <div>
                      <template>
                        <b-form-group label="Company Groups" label-for="companyGroups">
                          <draggable tag="div" class="draggable" v-model="websiteInformation.companyGroups">
                          <b-form-row class="align-items-center mb-2 slide" v-for="(v, key) in websiteInformation.companyGroups" :key="key">
                            <template v-if="v['name'] !== null">
                              <b-col md="10">
                                <b-form-row>
                                  <b-col md="6">
                                    <b-input-group>
                                      <template #prepend>
                                        <b-input-group-text><i class="icon-people"></i></b-input-group-text>
                                      </template>
                                      <b-form-input v-model="v['name']" placeholder="Company Group Name" @keyup="removeWildChar" @keydown="removeWildChar"/>
                                    </b-input-group>
                                    
                                    <VValidate 
                                      :name="'Company Group'+(key + 1)" 
                                      v-model="v['name']"
                                      :rules="{required:1, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                    />
                                  </b-col>
                                  <b-col md="6">
                                    <b-input-group>
                                      <template #prepend>
                                        <b-input-group-text><i class="icon-link"></i></b-input-group-text>
                                      </template>
                                      <b-form-input v-model="v['link']" placeholder="https://company-group-link.com"/>
                                    </b-input-group>
                                    
                                    <VValidate 
                                      :name="'Link'+(key + 1)" 
                                      v-model="v['link']"
                                      :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                                    />
                                  </b-col>
                                </b-form-row>
                              </b-col>
                              
                              <b-col md="2">
                                <b-button
                                    class="btn-icon"
                                    variant="outline-success"
                                    v-b-tooltip.hover="'Add Group'"
                                    @click="addCompany"
                                >
                                    <i class="fas fa-plus"></i>
                                </b-button>
                                <b-button
                                    class="btn-icon ml-1"
                                    variant="outline-danger"
                                    @click="deleteCompany(key)"
                                    v-if="lengthComp > 1"
                                    v-b-tooltip.hover="'Remove Group'"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </b-button>
                              </b-col>
                            </template>
                          </b-form-row>
                          </draggable>
                        </b-form-group>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <div class="text-right">
              <b-button variant="secondary" @click="toLatest('info')" class="btn-rounded mr-2">Cancel</b-button>              
              <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
            </div>
          </b-card-footer>
        </b-form>
        </validation-observer>

      </b-card>
      <validation-observer
            v-slot="{ handleSubmit }" ref="VFormSettings"
        >
        <b-form @submit.prevent="handleSubmit(submitSettings)">
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Website Settings</h5>
            </template>
            <b-card-body>
              <b-row>
                <!--
                <b-col md="3">
                  <b-form-group label-for="defLang" label="Website Default Language">
                    <v-select id="defLang" v-model="websiteSettings.lang" :options="languages" :reduce="el => el.value"
                      placeholder="Select Website Default Language" />
                    <VValidate name="Language" v-model="websiteSettings.lang" :rules="validationSettings.lang" />
                  </b-form-group>
                </b-col>
                -->
                <b-col md="3">
                  <b-form-group label-for="pagiItem" label="Articles Shown per Page">
                    <b-form-input id="pagiItem" v-model="websiteSettings.showPerPage" type="number" />
                    <VValidate name="Articles Shown per Page" v-model="websiteSettings.showPerPage"
                      :rules="validationSettings.showPerPage" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <div class="text-right">
                <b-button variant="secondary" @click="toLatest('settings')" class="btn-rounded mr-2">Cancel</b-button>
                <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-form>
      </validation-observer>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'

let $ = global.jQuery
let _ = global._
import draggable from 'vuedraggable'

export default {
  extends: GlobalVue,
  components: {
    draggable
  },
  data() {
    return {
        noTel: 0,
        websiteInformation:{},
        websiteSettings:{},
        validationSite: {},
        validationSettings: {},

        companyFooterDesc: 'Malacca Trust Wuwungan Insurance menyediakan beragam solusi produk asuransi jiwa dan umum untuk memenuhi kebutuhan perorangan dan bisnis.',
        defLang: 'Bahasa Indonesia',
        languages: [
            {label : 'Bahasa Indonesia',value : 'ID'},
            {label : 'English ', value : 'EN'},
        ],
        lengthComp : 0,
        websiteInformationLatest:{},
        websiteSettingsLatest:{}
    }
  },
  methods: {
    deleteCompany(key){
        let length = 0
        for(let i = 0; i < this.websiteInformation.companyGroups.length; i++){
            if(this.websiteInformation.companyGroups[i]['name'] !== null){
                length = length + 1
            }
        }
        if(length > 1){
            this.websiteInformation.companyGroups[key]['name'] = null
            this.lengthComp = this.lengthComp -1 
        }
    },
    addCompany(){
      if(this.lengthComp < 5){
        // let length = this.websiteInformation.companyGroups.length
        this.lengthComp = this.lengthComp + 1 
        let data = {}
        data["name"] = ""
        data["link"] = ""
        this.websiteInformation.companyGroups.push(data)
      }
    },

    deleteTel(key){
      this.websiteInformation.telephone.splice(key, 1)
    },
    addTel(){
      if(this.websiteInformation.telephone.length < 5){
        let data = {}
        data["tel" + (this.websiteInformation.telephone.length+ 1)] = ""
        this.websiteInformation.telephone.push(data)
      }
    },

    toLatest(data){
      this.$swal({
        title: `Do you want to discard this data?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#30d64c',
        confirmButtonText:  `Yes!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          if(data == 'info'){
            this.apiGet()
            this.websiteInformation = this.websiteInformationLatest
          }
          else{
            this.apiGet()
            this.websiteSettings = this.websiteSettingsLatest
          }
        }
      })
    },

    submitSettings(){
        this.doSubmit(
            "/do/" + this.modulePage,
            _.assign({
            type: 'updateSettings'
            }, this.websiteSettings),
            (type, resp) => {
            if (type == 'success') {
                this.loadingOverlay = true  
                this.apiGet()
            }else{
                if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                    var msg = ""
                    $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                    })
                    return this.$swal.fire("Blocked With Validation!", msg)
                } else {
                    return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
                } else if (resp.response.status == 400) {
                return this.$swal.fire({
                    title: resp.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                }
            }
            },
            'POST', 'VFormSettings'
        )
    },
    submitSite(){
        this.doSubmit(
            "/do/" + this.modulePage,
            _.assign({
            type: 'updateSite'
            }, this.websiteInformation),
            (type, resp) => {
            if (type == 'success') {
                this.loadingOverlay = true  
                this.apiGet()
            }else{
                if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                    var msg = ""
                    $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                    })
                    return this.$swal.fire("Blocked With Validation!", msg)
                } else {
                    return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
                } else if (resp.response.status == 400) {
                return this.$swal.fire({
                    title: resp.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                }
            }
            },
            'POST', 'VFormSite'
        )
    }


  },
  mounted() {
    this.apiGet()      
  },
  watch:{
    $route(){
        this.apiGet()
    }
  }
}
</script>
